import { Component, Prop } from 'vue-property-decorator'
import { DialogComponent } from 'vue-modal-dialogs'
import Button from '@/components/button/Button.vue'

@Component({
  components: {
    Button
  }
})

export default class BasicModal extends DialogComponent<boolean> {
  @Prop({ required: true, type: String }) readonly title: string;
  @Prop({ required: true, type: String }) readonly content: string;

  private preventClosing (event: Event) {
    event.preventDefault()
    event.stopPropagation()
  }
}
